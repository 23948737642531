import React from 'react';
import LayoutES from '../components/layout.es';
import Seo from '../components/seo.es';

export default function AvisoLegal() {
	return (
		<LayoutES>
			<Seo title='Términos y condiciones' />
			<div
				style={{
					margin: '0 auto',
					width: '80%',
					maxWidth: '800px',
					padding: '60px 0',
				}}
			>
				<h1>Términos y condiciones del servicio</h1>
				<p>Entidad: KUFLOW, S.L. (en adelante KUFLOW)</p>
				<p>CIF: B02951978</p>
				<p>Enero 2022</p>
				<h2>1. Objeto y aceptación</h2>
				<p>
					Las presentes condiciones (las “Condiciones”) regulan la adquisición y
					gestión por parte del usuario (en adelante, el “Usuario” o “usted”)
					del derecho a usar el servicio KUFLOW (el “Servicio”), a través del
					sitio web https://www.kuflow.com (el “Sitio Web de KUFLOW”), así como
					el uso del Servicio. El acceso y uso del Servicio puede llevarse a
					cabo a través de cualquiera de los medios que se indican a
					continuación: sistemas operativos iOs, Android, App o cualquier otro
					medio. El medio de acceso a la plataforma será a través de navegador
					web y a través de la interfaz de programación de aplicaciones (API) de
					la herramienta o cualquier otro medio establecido en cada momento por
					KUFLOW, S.L.
				</p>
				<p>
					Al acceder al Sitio Web de KUFLOW, al usar el Servicio o, según el
					caso, usted declara haber leído y aceptado en su totalidad las
					presentes Condiciones. Si usted no está de acuerdo con las presentes
					Condiciones o con alguna parte o cláusula de las mismas, deberá cesar
					inmediatamente en el acceso y uso del Sitio Web de KUFLOW o del
					Servicio. El acceso y uso del Sitio Web de KUFLOW y del Servicio por
					el Usuario está condicionado a su aceptación, sin reserva alguna, de
					las presentes Condiciones.
				</p>
				<p>
					Salvo que se indique otra cosa en estas Condiciones, estas podrán
					modificarse en cualquier momento por KUFLOW, S.L., previa notificación
					al Usuario en la cuenta de correo electrónico que figure en su
					registro. La fecha indicada al principio de las Condiciones
					corresponde a su última actualización. En caso de no estar de acuerdo
					con las nuevas Condiciones, con alguna parte o cláusula de las mismas,
					usted podrá cesar en el acceso y uso del Sitio Web de KUFLOW y del
					Servicio antes de su entrada en vigor. Si usted continúa utilizando el
					Sitio Web de KUFLOW y el Servicio, una vez que haya entrado en vigor
					la nueva versión de las Condiciones, se entiende que las ha leído y
					las acepta sin reserva alguna.
				</p>
				<h2>2. Capacidad para aceptar las condiciones</h2>
				<p>
					El Servicio está destinado a personas jurídicas o personas físicas
					mayores de 18 años, que actúen en su condición de empresas o
					profesionales. Este Servicio no está destinado a consumidores, por lo
					que no resultan de aplicación las regulaciones en ese ámbito.
				</p>
				<p>
					En caso de ser una persona física, al aceptar las presentes
					Condiciones, usted declara que tiene más de 18 años de edad y que está
					plenamente capacitado para asumir y cumplir lo establecido en las
					presentes Condiciones. Si usted es menor de 18 años de edad, por
					favor, no acceda ni use el Servicio.
				</p>
				<p>
					Si usted se ha registrado y está utilizando el Servicio en nombre de
					una persona jurídica, empresa o entidad, usted declara que está
					debidamente autorizado y legitimado para (I) representar a la persona
					jurídica, empresa o entidad, (II) aceptar las presentes Condiciones y
					gestionar la Cuenta (como este término se define a continuación) en
					nombre de esa persona jurídica, empresa o entidad, y (III) vincular a
					esa persona jurídica, empresa o entidad al cumplimiento de las
					presentes Condiciones. En ese caso, a los efectos de estas
					Condiciones, se considerará “usted” o el “Usuario” a la persona
					jurídica, empresa o entidad correspondiente.
				</p>
				<h2>3. Uso del servicio </h2>
				<p>
					El Usuario sólo está autorizado a acceder y utilizar el Servicio de
					conformidad con estas Condiciones. Usted se compromete a utilizar el
					Servicio de buena fe y a no emplearlo de forma negligente, con fines
					fraudulentos o de manera ilegal. Del mismo modo, usted se compromete a
					no llevar a cabo ninguna conducta o acción que pueda o pudiera dañar
					la imagen, los intereses y/o derechos de KUFLOW, S.L., de otros
					usuarios o de terceros, a título ejemplificativo, no limitativo, la
					utilización de ingeniería inversa y explotación del servicio, que
					pueda afectar a otros usuarios de la plataforma, así como vulnerar las
					medidas de seguridad y el acceso a datos e información confidencial de
					otros de usuarios.
				</p>
				<p>
					El Usuario será responsable de asegurarse, antes de utilizar el
					Servicio, de que sus características y sus diferentes funcionalidades
					satisfacen sus necesidades y de que dispone de los requisitos, el
					equipo y el software necesarios para ese fin, asumiendo los gastos
					necesarios para la utilización del Servicio.
				</p>
				<p>
					Los derechos otorgados al Usuario bajo estas Condiciones son
					personales y no podrán ser cedidos a terceros (incluyendo filiales,
					entidades de un mismo grupo de empresas o entidades vinculadas de
					cualquier otra forma), total o parcialmente, por cualquier medio, sin
					el consentimiento previo, expreso y por escrito de KUFLOW, S.L.
				</p>
				<p>
					La comercialización, reventa o cualquier uso similar del Servicio no
					está autorizada bajo ningún concepto, e implicará la terminación
					inmediata del Servicio en esos supuestos.
				</p>
				<p>
					Sin perjuicio de lo anterior, el Usuario estará autorizado a facilitar
					el acceso al Servicio a otros individuos tales como empleados,
					clientes, colaboradores o terceros (ya sea como Usuarios Contratados o
					Colaboradores y de conformidad con estas Condiciones) como un valor
					añadido en su relación comercial, de forma gratuita o remunerada. En
					esos casos, el Usuario es el único responsable de la relación
					contractual con esos empleados, clientes, colaboradores o terceros y
					del cumplimiento por estos de estas Condiciones, sin que KUFLOW, S.L.
					tenga ningún tipo de obligación o responsabilidad frente a los mismos.
				</p>
				<p>
					El Usuario es el único y exclusivo responsable de los citados archivos
					y de su actuación, contenido de los archivos o comunicaciones usando
					el Servicio, conforme a las indicaciones que se establezcan a
					continuación o en el Sitio Web. En ninguna circunstancia KUFLOW, S.L.
					controla, revisa, edita, garantiza o es responsable del contenido que
					el Usuario publique, almacene o comparta mediante el Servicio.
				</p>
				<h2>4. Registro</h2>
				<p>
					Para usar el Servicio, el Usuario debe registrarse en el Sitio Web de
					KUFLOW y crear y activar una cuenta de usuario (la “Cuenta”). Para
					ello, el Usuario deberá proporcionar la información de registro
					requerida en el Sitio Web de KUFLOW en cada momento. A estos efectos,
					el Usuario debe proporcionar una dirección de correo electrónico
					válida, a través de la cual reciba cualquier notificación relativa al
					Servicio. Una vez facilitada la información de registro por el
					Usuario, KUFLOW, S.L. enviará automáticamente a la cuenta de correo
					electrónico indicada por el Usuario en el registro, un correo
					electrónico para la validación de la Cuenta. La Cuenta se creará tras
					su validación por el Usuario.
				</p>
				<p>
					El Usuario reconoce que la información facilitada en el registro es
					veraz, completa y está actualizada. El Usuario será el único
					responsable de mantener dicha información actualizada. El Usuario no
					podrá hacerse pasar por otra persona en el registro.
				</p>
				<p>
					Si la información proporcionada por el Usuario en el registro no es
					veraz, o KUFLOW, S.L. tiene motivos para sospechar que dicha
					información es falsa, está incompleta o no se encuentra actualizada,
					KUFLOW, S.L. podrá suspender o cancelar la Cuenta, su uso del Servicio
					y/o los datos y archivos almacenados por el Usuario.
				</p>
				<p>
					El Usuario deberá custodiar su contraseña, tratarla de forma
					confidencial y no revelarla a otras personas. Asimismo, no debe
					publicar, distribuir o divulgar su contraseña. El Usuario nunca debe
					permitir que otros usuarios o terceros usen su Cuenta, ni debe usar la
					cuenta de otro usuario. El Usuario deberá informar inmediatamente a
					KUFLOW, S.L. de cualquier violación o sospecha de violación de
					seguridad, o uso no autorizado de su Cuenta.
				</p>
				<p>
					Si KUFLOW, S.L. tiene motivos para sospechar que la Cuenta está siendo
					utilizada por terceros no autorizados, KUFLOW, S.L. podrá suspender la
					Cuenta, su uso del Servicio y/o los datos y archivos almacenados por
					el Usuario.
				</p>
				<p>
					El Usuario es el único responsable del uso, configuración, gestión y
					administración de la Cuenta y de cualquier uso del Servicio a través
					de la Cuenta o por los Usuarios Contratados y Colaboradores adscritos
					a la Cuenta o por otros terceros a los que autorice el uso de
					funcionalidades del Servicio a través de cualquier dispositivo o
					ubicación, obligándose a indemnizar y mantener indemne a KUFLOW, S.L.{' '}
				</p>
				<h2>
					5. Alojamiento de archivos, gestión documental y control de procesos
					de KuFlow
				</h2>
				<p>
					KUFLOW incluye un servicio de alojamiento de archivos, gestión
					documental y control de procesos.
				</p>
				<p>
					Todos los archivos y datos almacenados, editados y gestionados serán
					alojados en servidores controlados por KUFLOW, S.L.
				</p>
				<p>
					Para disfrutar de todas las funcionalidades que ofrece el Servicio,
					como a título ejemplificativo y no limitativo, realizar tareas
					avanzadas y de configuración, establecer permisos o definir flujos de
					trabajo, el usuario deberá acceder a los datos y archivos almacenados
					a través de la Aplicación.
				</p>
				<p>
					El Usuario se asegurará de disponer de las autorizaciones que, en su
					caso, sean pertinentes para emplear marcas o nombres de terceros. El
					Usuario garantiza que es titular de los derechos necesarios para usar
					el Contenido del Usuario en los términos establecidos en este párrafo
					e indemnizará y mantendrá indemne a KUFLOW, S.L. frente a cualquier{' '}
					reclamación de terceros por el uso de ese Contenido del Usuario,
					asumiendo cualquier daño o perjuicio (incluyendo costes legales) que
					se pudiera derivar de esa reclamación.
				</p>
				<h2>6. Contratación del servicio</h2>
				<p>
					Para acceder y usar el Servicio, el Usuario deberá contratar una
					licencia (la “Licencia”).{' '}
				</p>
				<p>
					Al contratar un Plan, el Usuario almacenará, editará y gestionará sus
					archivos y datos, deberá determinar los parámetros, que, a su vez,
					determinarán el precio.
				</p>
				<p>
					Existen diferentes planes basados en la capacidad de uso de la
					plataforma, tanto en funcionalidades como en intensidad de uso.{' '}
				</p>
				<p>
					KUFLOW, S.L. podrá suspender o eliminar la Cuenta si considera que
					usted, o los Usuarios Contratados o Colaboradores adscritos a la
					Cuenta, no cumplen con lo establecido en las presentes Condiciones.
				</p>
				<h2>7. Precio del servicio</h2>
				<p>
					Para usar el Servicio objeto de una Licencia, el Usuario deberá abonar
					la cuota que resulte de la aplicación mensual en función del Plan y de
					los Parámetros del su uso en la Plataforma.{' '}
				</p>
				<p>
					En el Sitio Web de KUFLOW, el Usuario puede comprobar la Cuota
					aplicable al Plan con los diferentes Parámetros.
				</p>
				<p>
					La Cuota cubre el uso del software objeto del Servicio, el alojamiento
					y el servicio de mantenimiento, así como las funcionalidades
					adicionales, conforme al Plan y Parámetros contratados en los términos
					previstos en estas Condiciones.
				</p>
				<p>
					Las Cuotas mensuales deberán pagarse a mes vencido. Las Licencias se
					renovarán automáticamente y de manera sucesiva al vencimiento de cada
					mes.
				</p>
				<p>
					Los precios mostrados en el Sitio Web de KUFLOW para los Planes están
					expresados en euros y no incluyen, salvo que se indique lo contrario,
					ningún impuesto.
				</p>
				<p>
					KUFLOW, S.L. se reserva la facultad de modificar la tarifa aplicable
					al Servicio (lo que implicará una modificación de las Cuotas
					correspondientes) con un preaviso de, al menos, tres (3) meses de
					antelación a la fecha de aplicación de la modificación.{' '}
				</p>
				<p>
					Dicha modificación será notificada al Usuario mediante correo
					electrónico y se entenderá aceptada si el Usuario continúa usando el
					Servicio tras su entrada en vigor.
				</p>
				<h2>8. Pago y facturación</h2>
				<p>
					Para contratar una Licencia”, el Usuario deberá seguir las
					instrucciones en el Sitio Web de KUFLOW, lo que implicará facilitar
					los datos de una tarjeta de crédito o débito con la que realizar el
					pago de la Cuota y que quedará asociada a la Cuenta.
				</p>
				<p>
					El pago de la Cuota se realizará a través de una plataforma de pagos.
					El uso de la Plataforma de Pagos estará sujeto a los términos,
					condiciones y política de privacidad de la misma, los cuales deberán
					de ser aceptados por el Usuario. Los datos de la tarjeta de crédito o
					débito del Usuario serán almacenados directamente por la plataforma de
					pago, quien se encargará de almacenarlos y custodiarlos.{' '}
				</p>
				<p>
					KUFLOW, S.L. no tendrá acceso directo, en ningún caso, a los datos de
					la tarjeta de crédito o débito del Usuario introducidos en la
					Plataforma de Pagos. KUFLOW, S.L. no es responsable del funcionamiento
					de la Plataforma de Pagos.
				</p>
				<p>
					Al contratar la modalidad elegida, usted autoriza a KUFLOW, S.L. a
					realizar cargos a través de la Plataforma de Pagos. Para pagos
					sucesivos, las Cuotas se cargarán a mes vencido, de acuerdo con lo
					establecido en estas Condiciones.
				</p>
				<p>
					Al tratarse de servicios no destinados a consumidores, no resulta de
					aplicación ningún derecho de desistimiento ni las garantías
					establecidas en la normativa de consumidores y usuarios.
				</p>
				<p>
					La Licencia no estará activa hasta que se haya recibido el pago o haya
					sido autorizado por la entidad financiera correspondiente.
				</p>
				<p>
					En cada renovación, se cargará la Cuota correspondiente por el medio
					de pago establecido en la Cuenta en su momento.{' '}
				</p>
				<p>
					En caso de impago de cualquier Cuota, el Usuario no podrá acceder a
					los archivos almacenados en el mismo, ni usar las funcionalidades del
					Servicio.{' '}
				</p>
				<p>
					El Usuario podrá incluir o modificar sus datos de facturación, así
					como la información fiscal correspondiente, a través de su Cuenta. El
					Usuario es el único responsable de la veracidad de los datos aportados
					para calcular el tipo de impuesto aplicable, así como del tipo de
					factura a emitir.{' '}
				</p>
				<p>
					Si el Usuario es un operador intracomunitario puede solicitar una
					validación de sus datos para que se le aplique el impuesto correcto.
					El Usuario se obliga a indemnizar y mantener indemne a KUFLOW, S.L.
					respecto de aquellos daños, costes, perjuicios, reclamaciones,
					demandas y acciones en que incurra o pueda incurrir KUFLOW, S.L. como{' '}
					consecuencia o en relación con el incumplimiento de los compromisos
					asumidos en este párrafo.
				</p>
				<h2>9. Restricciones</h2>
				<p>
					En relación con el uso del Servicio, del Sitio Web de KUFLOW o de los
					Medios de Acceso, usted no podrá:{' '}
				</p>
				<p>
					Realizar actuaciones de ingeniería inversa, descompilar, descodificar
					o hackear la tecnología que KUFLOW, S.L. utiliza para prestar el
					Servicio.{' '}
				</p>
				<p>
					Hacerse pasar por otra persona o entidad, o declarar falsamente o
					tergiversar su vinculación o asociación con una persona o entidad.
				</p>
				<p>
					Interferir, interrumpir, o violar la seguridad del Servicio, del Sitio
					Web de KUFLOW o de los Medios de Acceso, o de los servidores o redes
					utilizadas por, KUFLOW, S.L. para prestar el Servicio o para operar el
					Sitio Web de KUFLOW, S.L. o los Medios de Acceso.
				</p>
				<p>
					Realizar cualquier acto que dañe, inutilice, sobrecargue (según lo
					determinado por KUFLOW, S.L. a su sola discreción) o deteriore o
					impida el normal uso del Servicio, del Sitio Web de KUFLOW o de los
					Medios de Acceso, que afecte a su seguridad, o que dificulte o
					interfiera en los mismos.
				</p>
				<p>
					Interferir o intentar interferir en el adecuado funcionamiento del
					Servicio, del Sitio Web de KUFLOW o de los Medios de Acceso
				</p>
				<p>
					Acceder al Servicio mediante cualquier programa, aplicación o
					herramienta distintas a las proporcionadas por KUFLOW, S.L.
					(excluyendo las creadas según las condiciones del API), o destinada a
					cualquier propósito distinto al uso normal del Servicio, tales como
					intentos maliciosos para lograr el control del Servicio o la negación
					del mismo, pruebas de rendimiento o comparativas. Esta restricción
					aplica al Sitio Web de KUFLOW y a los Medios de Acceso, así como a
					cualquier actividad electrónica que tenga como destino las
					infraestructuras de KUFLOW, S.L. (sus servidores y sus direcciones IP
					públicas), que sea contraria a su explotación normal de acuerdo a lo
					establecido en las presentes Condiciones o que lesione o pudiera
					lesionar injustificadamente los intereses de KUFLOW, S.L., sin
					autorización expresa previa y por escrito de KUFLOW, S.L.
				</p>
				<h2>10. Cancelación del servicio</h2>
				<p>
					El Usuario reconoce que KUFLOW, S.L. tendrá derecho a cancelar el
					Servicio y, por tanto, a cancelar el acceso y uso del mismo por el
					Usuario (incluyendo el acceso al Sitio Web de KUFLOW o a los Medios de
					Acceso y a los datos y archivos almacenados, incluso por cualquier
					Usuario Contratado o Colaborador adscrito a la Cuenta) cuando
					incumpla, o KUFLOW, S.L. tenga sospechas del incumplimiento de la
					presentes Condiciones, en cualquier momento, con o sin previo aviso, y
					con efecto inmediato.{' '}
				</p>
				<p>
					En especial KUFLOW, S.L. cancelará el servicio de inmediato si el
					usuario lo utiliza con fines fraudulentos o de manera ilegal, o
					realice acciones ilícitas, como la contratación de nuestros servicios
					de hosting para su publicación en una página web ilegal.
				</p>
				<p>
					La cancelación del Servicio dará lugar a la terminación de la relación
					contractual entre ambas partes, e implicará que el Usuario no tendrá
					acceso a sus archivos almacenados en los mismos, ni al resto de
					funcionalidades del Servicio.{' '}
				</p>
				<p>
					El Usuario podrá cancelar el Servicio en cualquier momento, siguiendo
					las instrucciones facilitadas en el Sitio Web de KUFLOW.{' '}
				</p>
				<p>
					Todo lo anterior, sin perjuicio de las obligaciones de conservación de
					documentación que, en su caso, pudieran resultar de la normativa
					aplicable. Todas las disposiciones previstas en las presentes
					Condiciones que por su naturaleza deberían seguir vigentes tras la
					terminación de la relación contractual entre el Usuario y KUFLOW,
					S.L., seguirán vigentes tras la terminación de la relación
					contractual, incluyendo, sin limitación, las disposiciones relativas a
					propiedad intelectual, exclusión de garantía, indemnizaciones y
					limitaciones de responsabilidad.
				</p>
				<h2>11. Derechos propiedad de Propiedad Industrial e Intelectual</h2>
				<p>
					Todos los derechos de propiedad industrial e intelectual sobre el
					Servicio, el Sitio Web de KUFLOW o los Medios de Acceso, su diseño y
					el código fuente, incluyendo, sin limitación, los logotipos, marcas,
					signos distintivos, diseño gráfico o software pertenecen y son
					propiedad, íntegra y exclusiva de KUFLOW, S.L. o sus licenciantes y
					están protegidos por leyes internacionales de propiedad industrial e
					intelectual.
				</p>
				<p>
					El Usuario sólo está autorizado a acceder y utilizar el Servicio, el
					Sitio Web de KUFLOW o los Medios de Acceso conforme a lo dispuesto por
					KUFLOW, S.L. y dentro de lo permitido por las presentes Condiciones.
					Excepto cuando esté específicamente autorizado para ello, el Usuario
					no podrá reproducir, transformar, distribuir, realizar obras{' '}
					derivadas, poner a disposición, comunicar a terceros, extraer,
					reutilizar y/o utilizar el Servicio, el Sitio Web de KUFLOW o los
					Medios de Acceso.
				</p>
				<p>
					Al contratar el Servicio, KUFLOW, S.L. otorga al Usuario autorización
					personal, limitada, revocable, no exclusiva e intransferible para usar
					el Servicio (incluyendo el software objeto del mismo), de conformidad
					con los términos previstos en estas Condiciones.
				</p>
				<p>
					El Usuario será el titular de los archivos almacenados, editados o
					gestionados a través del Servicio. KUFLOW, S.L. no tiene ningún
					derecho sobre dichos archivos a excepción de los derechos necesarios
					para prestar el Servicio. Como consecuencia, el Usuario otorga a
					KUFLOW, S.L., que acepta, una licencia de uso no exclusiva, gratuita y
					universal para acceder a los archivos almacenados, editados o
					gestionados por el Usuario cuando la prestación del Servicio así lo
					requiera, lo que incluye, cuando sea necesario, el cumplimiento de la
					normativa aplicable, la aplicación de estas Condiciones, la detección
					y prevención de fraudes y problemas técnicos o de seguridad, o la
					protección de los derechos de KUFLOW, S.L., otros usuarios o terceros.{' '}
				</p>
				<p>
					El Usuario garantiza que dispone de todos los derechos necesarios
					sobre los archivos para su almacenamiento, edición y gestión a través
					del Servicio y para el otorgamiento de la licencia anterior a favor de
					KUFLOW, S.L. e indemnizará y mantendrá indemne a KUFLOW, S.L. frente a
					cualquier reclamación de terceros por el uso de esos archivos,
					asumiendo cualquier daño o perjuicio (incluyendo costes legales) que
					se pudiera derivar de esa reclamación.
				</p>
				<p>
					KUFLOW, S.L. no permite realizar actividades que infrinjan derechos de
					propiedad industrial o intelectual a través del Servicio. KUFLOW, S.L.
					eliminará cualquier archivo almacenado, editado o gestionado si se le
					notifica debidamente que dicho archivo infringe derechos de propiedad
					industrial o intelectual de terceros. En este caso, KUFLOW, S.L. se
					reserva el derecho a eliminar cualquier archivo sin previo aviso.
				</p>
				<h2>12. Protección de datos de carácter personal</h2>
				<p>
					En el caso de que la prestación del Servicio requiera el tratamiento
					de datos de carácter personal responsabilidad del Usuario, por parte
					de KUFLOW, S.L., KUFLOW, S.L. actuará en calidad de encargado del
					tratamiento en los términos establecidos en el Reglamento (UE)
					2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016,
					relativo a la protección de las personas físicas en lo que respecta al
					tratamiento de datos personales y a la libre circulación de estos
					datos y por el que se deroga la Directiva 95/46/CE (en adelante, el
					“RGPD”).{' '}
				</p>
				<p>
					Dicho acceso a los datos de carácter personal no tendrá la
					consideración de comunicación o cesión de datos, sino de simple acceso
					a los datos por cuenta del Usuario para prestar el Servicio.
				</p>
				<p>
					El objeto, la duración y la naturaleza del tratamiento de los datos
					personales son los definidos para el Servicio en estas Condiciones y
					la finalidad del tratamiento de datos personales es la prestación del
					Servicio.
				</p>
				<p>
					KUFLOW, S.L., en cumplimiento de lo establecido en el RGPD, se
					compromete a:
				</p>
				<p>
					Utilizar dichos datos siguiendo las instrucciones documentadas del
					Usuario, en los términos previstos en el artículo 28.3.a) del RGPD. En
					caso de que, en opinión de KUFLOW, S.L., las instrucciones del Usuario
					sean contrarias a la normativa de protección de datos, KUFLOW, S.L.
					informará de ello inmediatamente al Usuario.
				</p>
				<p>
					Tratar los datos personales de conformidad con los criterios de
					seguridad establecidos en la normativa de protección de datos y, en
					particular, en el artículo 32 del RGPD.
				</p>
				<p>
					Asistir al Usuario para garantizar el cumplimiento de las obligaciones
					establecidas en los artículos 32 a 36 del RGPD, teniendo en cuenta la
					naturaleza del tratamiento y la información a disposición de KUFLOW,
					S.L.
				</p>
				<p>
					Garantizar que las personas autorizadas para tratar los datos
					personales se hayan comprometido a respetar la confidencialidad o
					estén sujetas a una obligación de confidencialidad de naturaleza
					estatutaria.
				</p>
				<p>
					Comunicar al Usuario, sin dilación indebida, las violaciones de
					seguridad sufridas sobre los datos personales y que sean susceptibles
					de comunicación conforme a los artículos 33 y 34 del RGPD o en
					cualquier otra normativa aplicable.
				</p>
				<p>
					Colaborar con el Usuario de manera razonable en la realización de
					evaluaciones de impacto relativas a la protección de datos y consultas
					previas al respecto a las autoridades competentes; teniendo en cuenta
					la naturaleza del tratamiento y la información de la que disponga.
				</p>
				<p>
					Asistir al Usuario, teniendo en cuenta la naturaleza del tratamiento,
					a través de medidas técnicas y organizativas apropiadas, siempre que
					sea posible, para que el Usuario pueda cumplir con su obligación de
					responder a las solicitudes de ejercicio de derechos por parte de los
					interesados.
				</p>
				<p>
					Poner a disposición del Usuario correspondiente la información
					necesaria para demostrar el cumplimiento de las obligaciones previstas
					en esta Cláusula, siempre conforme a criterios de razonabilidad.
				</p>
				<p>
					No recurrir a otro encargado del tratamiento que requiera del acceso a
					los datos personales sin la autorización previa por escrito del
					Usuario, a salvo de proveedores de servicios de alojamiento, que están
					expresamente autorizados por la presente.
				</p>
				<p>
					Una vez cumplida la prestación de los Servicios, suprimir todos los
					datos personales a los que haya accedido, y suprimir las copias
					existentes, a menos que se requiera la conservación de los datos
					personales en virtud de la normativa aplicable.
				</p>
				<p>
					El Usuario manifiesta y garantiza a KUFLOW, S.L. que cumple y ha
					cumplido puntual, íntegra y exactamente, en relación con los
					tratamientos de datos de carácter personal encargados a KUFLOW, S.L.,
					con todas las obligaciones formales y materiales derivadas de la
					normativa de protección de datos de carácter personal, obligándose a
					indemnizar y mantener indemne a KUFLOW, S.L. respecto de aquellos
					daños, costes, perjuicios, reclamaciones, demandas y acciones en que
					incurra o pueda incurrir KUFLOW, S.L. como consecuencia o en relación
					con el incumplimiento de lo anterior.
				</p>
				<h2>13. Confidencialidad</h2>
				<p>
					Tanto los datos y documentos proporcionados por el Usuario como los
					archivos almacenados serán estrictamente confidenciales, de tal forma
					que únicamente se emplearán para prestar el Servicio y no se revelarán
					a terceros diferentes de empleados o subcontratistas de KUFLOW, S.L. o
					terceros indicados por el Usuario.
				</p>
				<p>
					Con carácter general, los empleados o subcontratistas de KUFLOW, S.L.
					no tendrán acceso a los mismos. Los empleados de KUFLOW, S.L. podrán
					acceder a los metadatos de los archivos siempre y cuando exista una
					necesidad suficientemente justificada, pero no al contenido de los
					archivos.
				</p>
				<p>
					No obstante, en aquellos supuestos excepcionales en los que la
					normativa aplicable así lo requiera, un determinado número de
					empleados de KUFLOW, S.L. podrá acceder a los archivos almacenados o a
					los datos o documentos proporcionados por el Usuario.
				</p>
				<h2>14. Responsabilidad</h2>
				<p>
					El Usuario reconoce y acepta que usa el Servicio en todo momento bajo
					su propio riesgo y responsabilidad, por lo que KUFLOW, S.L. no se
					responsabiliza del mal uso o uso indebido del Servicio o la violación
					de las presentes Condiciones.
				</p>
				<p>
					Si usted accede al Servicio, lo hace bajo su propio riesgo y es
					responsable del cumplimiento de las leyes de su jurisdicción.
				</p>
				<p>
					Si para la utilización y/o contratación de cualquier funcionalidad del
					Servicio, el Usuario debiera proceder a su registro, el Usuario será
					responsable de aportar información veraz y lícita. Si como
					consecuencia del registro o para el uso de cualquier finalidad del
					Servicio, se dotara al Usuario de una contraseña, éste se compromete a
					hacer un uso diligente y a mantener en secreto la contraseña para
					acceder al Servicio.{' '}
				</p>
				<p>
					En consecuencia, el Usuario es responsable de la adecuada custodia y
					confidencialidad de cualesquiera identificadores y/o contraseñas que
					le sean suministradas por KUFLOW, S.L., y se compromete a no ceder su
					uso a terceros no autorizados, ya sea temporal o permanente. Será
					responsabilidad del Usuario la utilización ilícita del Servicio por{' '}
					cualquier tercero ilegítimo que emplee a tal efecto una contraseña a
					causa de una utilización no diligente o de la pérdida de la misma por
					el Usuario.
				</p>
				<p>
					En virtud de lo anterior, es obligación del Usuario notificar de forma
					inmediata a KUFLOW, S.L., cualquier hecho que permita el uso indebido
					de los identificadores y/o contraseñas, tales como el robo, extravío,
					o el acceso no autorizado a los mismos, con el fin de proceder a su
					inmediata cancelación. Mientras no se comuniquen tales hechos, KUFLOW,
					S.L. quedará eximido de cualquier responsabilidad que pudiera
					derivarse del uso indebido de los identificadores o contraseñas por
					terceros no autorizados.
				</p>
				<p>
					Respecto a los archivos del Usuario almacenados o gestionados a través
					del Servicio, KUFLOW, S.L. actúa exclusivamente como prestador de un
					servicio de intermediación que consiste en permitir que los Usuarios
					puedan almacenar sus archivos y transmitirlos a través del Servicio.
					La responsabilidad por el almacenamiento y gestión de archivos del
					Usuario o cualquier otra información, datos, texto, software, archivos
					de sonido, fotografías, gráficos, vídeos, noticias u otros materiales,
					recae exclusivamente sobre el Usuario que almacena o transmite los
					archivos, excepto en las circunstancias específicas previstas por la
					ley aplicable. KUFLOW, S.L., en ningún, caso será responsable de los{' '}
					archivos almacenados o del contenido de los mismos.
				</p>
				<h2>15. Ley aplicable y jurisdicción</h2>
				<p>
					Las presentes Condiciones se regirán e interpretarán conforme a las
					leyes del Reino de España, salvo que por la condición del Usuario y la
					normativa aplicable de forma imperativa resulte de aplicación de forma
					obligatoria cualquier otra normativa.
				</p>
			</div>
		</LayoutES>
	);
}
